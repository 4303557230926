import { useState, useEffect } from "react";
import Select from 'react-select'
import Switch from "react-switch";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";



const ExpectedLearning = () => {


    let { id } = useParams();

    const headerProps = {
        title: `${id>0 ? "Editar" : "Agregar"} aprendizaje esperado`,
        parentName: "Competencias",
        parentLink: "/competencies",
      };
      
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
const [expectedLearning, SetExpectedLearning] = useState();
const [competencies, SetCompetencies] = useState();
const [subjects, SetSubjects] = useState();
const [grades, SetGrades] = useState();
const [tags, SetTags] = useState();
const [expectedLearningTags, SetExpectedLearningTags] = useState();

useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getExpectedLearning = async () => {
      try {
        const response = await axiosPrivate.get(`/recreo-gw/ExpectedLearnings/${id}`, {
          signal: controller.signal,
        });
        // console.log(response?.data);
        isMounted && SetExpectedLearning(response?.data);
        handleGradeChange({value:response?.data.competency.subject.grade.gradeId})
        handleSubjectChange({value:response?.data.competency.subject.subjectId})
        //handleCompetencyChange({value:response?.data.competency.competencyId})

      } catch (err) {
        if (err?.message !== "canceled") {
          // console.log(err);
        }
      }
      try {
        const response = await axiosPrivate.get(`/recreo-gw/expectedLearningTags/expectedLearning/${id}`, {
          signal: controller.signal,
        });
        isMounted && SetExpectedLearningTags(response?.data)
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    const getTags = async () =>{
      try {
        const response = await axiosPrivate.get(`/recreo-gw/Tags/Type?tagType=ELEARNING`, {
          signal: controller.signal,
        });
        //// //console.log(response?.data);
        isMounted && SetTags(response?.data)
      } catch (err) {
        if (err?.message !== "canceled") {
          // //console.log(err);
        }
      }
    };

    const getCatalogs = async () => {
        try {
            
               
               const response = await axiosPrivate.get("/recreo-gw/Grades", {
                signal: controller.signal,
              });
               SetGrades(response?.data.map( item => {
                  const { gradeId: value, name: label, ...rest} = item;
                  return { value,label, ...rest }
                 }));
         

        } catch (err) {
          if (err?.message !== "canceled") {
            // console.log(err);
          }
        }
      };

      getCatalogs();
      getTags();

    if(id>0){
        getExpectedLearning();
    }
    

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleSubmit = async (event) => {

    event.preventDefault();
   
    try {

         delete expectedLearning.competency;
        if(id === "0"){
         var response = await axiosPrivate.post(
            "/recreo-gw/expectedLearnings",
            JSON.stringify(expectedLearning)
          );
          let tagIds = expectedLearningTags.map(x => x.tagId);
          await axiosPrivate.post(
            `/recreo-gw/expectedLearningTags/Tags/${response?.data.expectedLearningId}`,
            JSON.stringify(tagIds)
          );
        }else{
          
          await axiosPrivate.put(
            "/recreo-gw/expectedLearnings/"+expectedLearning.expectedLearningId,
            JSON.stringify(expectedLearning)
          );
          let tagIds = expectedLearningTags.map(x => x.tagId);
          await axiosPrivate.put(
            `/recreo-gw/expectedLearningTags/Tags/${expectedLearning.expectedLearningId}`,
            JSON.stringify(tagIds)
          );
        }

     
        navigate("/expectedLearnings/"+expectedLearning.competencyId);
      
      
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      }
    }


  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '300px',
      'minWidth': '300px',
    }),
  };

  const handleGradeChange  = async (option) =>{
    try {
        const response = await axiosPrivate.get("/recreo-gw/Subjects/Grade/"+option.value);
        SetSubjects(response?.data.map( item => {
            const { subjectId: value, name: label, ...rest } = item;
            return { value,label , ...rest}
           }));
        if(id==='0' && typeof expectedLearning === 'undefined'){
            // console.log("hey");
            SetExpectedLearning({competency:{subject:{grade:{gradeId:option.value}},competencyId:0}});
        }else if(expectedLearning) {
            // console.log("hey2");
            expectedLearning.competency.subject.grade.gradeid = option.value;
            SetExpectedLearning(expectedLearning);
        }
        

      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
  }

  const handleSubjectChange  = async (option) =>{
    //console.log(option);
    try {
        const response = await axiosPrivate.get("/recreo-gw/Competencies/Subject/"+option.value);
        SetCompetencies(response?.data.map( item => {
            const { competencyId: value, name: label, ...rest } = item;
            return { value,label , ...rest}
           }));
        if(expectedLearning){
            expectedLearning.competency.subject.subjectId = option.value;
            SetExpectedLearning(expectedLearning);
        }
        // console.log(expectedLearning);
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
  }

  
  
  const handleSwitchChange = nextChecked => {
    // console.log(nextChecked);
    if(!expectedLearning){
        // console.log("sdasd");
        SetExpectedLearning({
            isActive:nextChecked
        });
    }else{
        SetExpectedLearning({ ...expectedLearning, isActive: nextChecked })
    }
    //console.log(expectedLearning);
  };

  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id>0 ? "Editar" : "Agregar"} aprendizaje esperado
              </h3>
            </div>
            <div className="card-body row">
              
            <div className="input-group mb-3 col-12">
            <label >Id: &nbsp; </label>
                {expectedLearning?.expectedLearningId }
                  
            </div>
            <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="grade"
                    placeholder="Grado"
                    options = {grades}
                    styles = {customStyles}
                    value={ grades?.filter( grade => grade.value === 
                      expectedLearning?.competency?.subject.grade.gradeId)}
                    onChange={(e) => handleGradeChange(e)}
                    />
            </div>

                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="subject"  
                    placeholder="materia"
                    options = {subjects}
                    value={ subjects?.filter( subject => subject.value === 
                      expectedLearning?.competency?.subject.subjectId)}
                    styles = {customStyles}
                    onChange={(e) => handleSubjectChange(e)}
                    />
                </div>

                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="competency"  
                    placeholder="Competencia"
                    options = {competencies}
                    value={ competencies?.filter( competency => competency.value === 
                      expectedLearning?.competencyId)}
                    styles = {customStyles}
                    onChange={(e) => SetExpectedLearning({ ...expectedLearning, competencyId:e.value })}
                    />
                </div>

                <div className="input-group mb-3 col-3">
                <label >Activo: &nbsp; </label>
                
                <Switch 
                    onChange={handleSwitchChange} 
                    checked={expectedLearning?.isActive ? true: false } 
                    // height={30}
                    // handleDiameter={30}
                    />
                </div>

            

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-cubes" />
                  </span>
                </div>
                <input
                  id="name"
                  type="textarea"
                  name="name"
                  className="form-control"
                  placeholder="Tema"
                  required = {true}
                  value={expectedLearning?.name}
                  onChange ={(e) => SetExpectedLearning({ ...expectedLearning, name: e.target.value })}
                  
                /> 
              </div>

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tag" />
                  </span>
                </div>
                <input
                  type="textarea"
                  name="description"
                  className="form-control"
                  required = {true}
                  placeholder="Descripcion"
                  value={expectedLearning?.description}
                  onChange ={(e) => SetExpectedLearning({ ...expectedLearning, description: e.target.value })}
                />
              </div>

              <div className="input-group mb-3 col-8">
                <label >Clasificación: &nbsp;</label>
                    <Select 
                    name="tags"  
                    placeholder="categorias"
                    isMulti
                    options = {tags}
                    getOptionLabel={(option) => `${option.tagClass}-${option.name}`}
                    getOptionValue={(option) => option.tagId}
                    value={ tags?.filter( tag => expectedLearningTags?.some(qt => qt.tagId === tag.tagId))}
                    styles = {customStyles}
                    onChange ={(e) => SetExpectedLearningTags(e)}
                    />
                </div>

              
              
            

              
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default ExpectedLearning
