import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const MainCharts = (props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  const chartColors = [
    "rgba(254, 228, 64, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(177, 167, 166, 1)",
    "rgba(0, 245, 212, 1)",
  ];

  const resultsData = {
    labels: props.schools.map((school) => school.schoolCode),
    datasets: [
      {
        label: "",
        data: props.schools.map((school) => school.lessonsPostAverage * 10),
        backgroundColor: chartColors,
      },
    ],
  };
  const resultOptions = JSON.parse(JSON.stringify(options));
  resultOptions.scales= {
    yAxes: {
      max: 10,
      ticks: {
        beginAtZero: true,
      },
    },
  };

  const progressData = {
    labels: props.schools.map((school) => school.schoolCode),
    datasets: [
      {
        label: "",
        data: props.schools.map((school) => school.quizProgress),
        backgroundColor: chartColors,
      },
    ],
  };
  const progressOptions = JSON.parse(JSON.stringify(options));
  progressOptions.scales = {
    yAxes: {
      max: 100,
      ticks: {
        beginAtZero: true,
      },
    },
  };

  let usageHours = [].concat.apply(
    [],
    props.schools.map((school) => school.usageHours)
  );

  const usageHoursSum = Array.from(
    usageHours.reduce(
      (m, { hour, questions }) => m.set(hour, (m.get(hour) || 0) + questions),
      new Map()
    ),
    ([hour, questions]) => ({ hour, questions })
  );

  const allQuestions = usageHoursSum
    .map((item) => item.questions)
    .reduce((prev, curr) => prev + curr, 0);

  const UsageData = {
    labels: usageHoursSum.map((item) => item.hour),
    datasets: [
      {
        label: "",
        data: usageHoursSum.map(
          (item) =>
            Math.round(((item.questions * 100) / allQuestions) * 100) / 100
        ),
        fill: true,
        lineTension: 0,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 2,
      },
    ],
  };

  let devices = [].concat.apply(
    [],
    props.schools.map((school) => school.devices)
  );

  const devicesSum = Array.from(
    devices.reduce(
      (m, { name, quantity }) => m.set(name, (m.get(name) || 0) + quantity),
      new Map()
    ),
    ([name, quantity]) => ({ name, quantity })
  );

  const allDevices = devicesSum
    .map((item) => item.quantity)
    .reduce((prev, curr) => prev + curr, 0);

  const deviceData = {
    labels: devicesSum.map((item) => item.name),
    datasets: [
      {
        label: "",
        data: devicesSum.map(
          (item) => Math.round(((item.quantity * 100) / allDevices) * 100) / 100
        ),
        backgroundColor: chartColors.map((item) => item.replace("1)", "0.2)")),
        borderColor: chartColors,
      },
    ],
  };

  const deviceOptions = JSON.parse(JSON.stringify(options));
  deviceOptions.plugins.legend.display = true;

  return (
    <>
      <div className="row">
        <section className="col-lg-6 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Resultados
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body" style={{ height: "350px" }}>
              <Bar options={resultOptions} data={resultsData} />
            </div>
          </div>
        </section>

        <section className="col-lg-6 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Porcentaje de participación
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body" style={{ height: "350px" }}>
              <Bar options={progressOptions} data={progressData} />
            </div>
          </div>
        </section>
      </div>

      <div className="row">
        <section className="col-lg-6 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Horarios
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body" style={{ height: "350px" }}>
              <Line options={options} data={UsageData} />
            </div>
          </div>
        </section>

        <section className="col-lg-6 connectedSortable ui-sortable">
          <div className="card">
            <div
              className="card-header ui-sortable-handle"
              style={{ cursor: "move" }}
            >
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1" />
                Dispositivos
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body" style={{ height: "350px" }}>
              <Pie options={deviceOptions} data={deviceData} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MainCharts;
