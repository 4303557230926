import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";


const Tag = (props) => {

    let { id } = useParams();
    const headerProps = {
            title: `${id>0 ? "Editar" : "Agregar"} tag`,
            parentName: "Tags",
            parentLink: "/tags",
          };
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [tag, SetTag] = useState();
    const [newTagClass, SetNewTagClass] = useState('');
    const [newTagType, SetNewTagType] = useState('TOPIC');
    const [newTagName, SetNewTagName] = useState('');
    const [newTagDesc, SetNewTagDesc] = useState('');

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getTag = async () => {
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Tags/${id}`, {
              signal: controller.signal,
            });
            //// console.log(response?.data);
            isMounted && SetTag(response?.data)
            SetNewTagName(response?.data?.name);
            SetNewTagClass(response?.data?.tagClass);
            SetNewTagDesc(response?.data?.description);
            SetNewTagType (response?.data?.tagType);

          } catch (err) {
            if (err?.message !== "canceled") {
              // console.log(err);
            }
          }
        };
        if(id>0){
            getTag();
        }
        
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);

    const handleSubmit = async (event) => {

      ///Todo: send the original object

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        var object = {};
        formData.forEach(function (value, key) {
          object[key] = value;
        });
        //console.log(object);
        try {
            if(id === "0"){
              await axiosPrivate.post("/recreo-gw/Tags", JSON.stringify(object));
            }else{
              object.tagId = parseInt(id);
              await axiosPrivate.put("/recreo-gw/Tags/"+id, JSON.stringify(object));
            }

         
            navigate("/tags");
          
          
        } catch (err) {
          if (!err?.response) {
            // console.log("No Server Response");
          }
        }


      };


  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id>0 ? "Editar" : "Agregar"} tag
              </h3>
            </div>
            <div className="card-body row">
              

              <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-cubes"></i>
                    </span>
                  </div>
                  <select 
                  id="tagType"
                  name="tagType" 
                  value={newTagType} 
                  onChange ={(e) => SetNewTagType(e.target.value)}
                  className="custom-select rounded-0">
                    <option value = "TOPIC">TOPIC</option>
                    <option value = "QUESTION">QUESTION</option>
                    <option value = "COMPETENCY">COMPETENCY</option>
                    <option value = "ELEARNING">EXPECTED LEARNING</option>
                  </select>
                </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tags" />
                  </span>
                </div>
                <input
                  id="tagClass"
                  type="text"
                  name="tagClass"
                  className="form-control"
                  placeholder="Clasificacion"
                  value={newTagClass}
                  onChange ={(e) => SetNewTagClass(e.target.value)}
                  
                /> 
              </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tag" />
                  </span>
                </div>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nombre de la etiqueta"
                  value={newTagName}
                  onChange ={(e) => SetNewTagName(e.target.value)}
                />
              </div>
              
              
              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-paragraph" />
                  </span>
                </div>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  placeholder="Descripcion"
                  value={newTagDesc}
                  onChange ={(e) => SetNewTagDesc(e.target.value)}
                />
              </div>
              
              
              
              <input
                  type="hidden"
                  name="id"
                  value={tag?.tagId}
                />
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default Tag
