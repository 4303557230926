import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const NewResources = () => {
    const [resources, SetResources] = useState();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: "Nuevos recursos",
      parentName: "Home",
      parentLink: "/",
     
    };
    const columns = [
      {
          name: "Nombre",
          selector: (row) => row.name,
          sortable: true,
          width: '320px',
          wrap: true
        },
      {
        name: "Id",
        selector: (row) => row.fileId,
        sortable: true,
        width: '300px',
        wrap: true
      },
      
      {
        name: "Description",
        selector: (row) =>row.description,
        sortable: true,
        width: '320px',
        wrap: true
      },
      {
        name: "Creado por",
        selector: (row) =>row.createdBy,
        sortable: true,
        width: '150px',
        wrap: true
      },
      {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
          <Link to={`/resource/${row.topicId}/${row.fileId}`}   >
          <button type="button" className="btn btn-primary btn-sm">
            <i className="fas fa-pencil-alt" />
          </button>
          </Link>
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
        }
    ];
  
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
  
      const getResources = async () => {
        try {
          const response = await axiosPrivate.get("/recreo-gw/Content/GetResourcesToApprove", {
            signal: controller.signal,
          });
          var resources = [].concat.apply([], response?.data.map(
            x => x.resources.map(r => ({...r, topicId : x.topicId}) )
            ))
          // var topicResources = response?.data.map(x => x.resources.map(r => ({...r, topicId : x.topicId}) ))
          // console.log(topicResources)
          // console.log(resources.map( item => {
          //   const { fileName: name, ...rest } = item;
          //   return { name, ...rest }
          //  }));
          isMounted && SetResources(resources.map( item => {
            const { fileName: name, ...rest } = item;
            return { name, ...rest }
           }));
        } catch (err) {
          if (err?.message !== "canceled") {
            console.log(err);
          }
        }
      };
  
      getResources();
  
      return () => {
        isMounted = false;
        controller.abort();
      };
    }, []);
    
    const tableProps = {
      columns: columns,
      data: resources
    };
  
    return (
      <section>
        <ContentHeader {...headerProps} />
        <section className="content">
          <div className="container-fluid">
            <CustomDataTable {...tableProps} />
          </div>
        </section>
      </section>
    )
}

export default NewResources
