import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const ResourceReports = () => {
    const [reports, SetReports] = useState();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: "Recursos reportados",
      parentName: "Home",
      parentLink: "/"
    };
    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
            width: '320px',
            wrap: true
          },
        {
          name: "Id",
          selector: (row) => row.fileId,
          sortable: true,
          width: '280px',
        },
      {
          name: "Reporte",
          selector: (row) => row.reports.filter(r => {return r.reportStatus === "NEW"})[0].reportText,
          sortable: true,
          width: '320px',
          wrap: true
        },
        {
            name: "Reportado por",
            selector: (row) => row.reports.filter(r => {return r.reportStatus === "NEW"})[0].createdBy,
            sortable: true,
            width: '280px',
          },
      {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
          <Link to={`/resource/${row.topicId}/${row.fileId}`}   >
          <button type="button" className="btn btn-primary btn-sm">
            <i className="fas fa-pencil-alt" />
          </button>
          </Link>
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
        }
    ];
  
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
  
      const getReports = async () => {
        try {
          const response = await axiosPrivate.get("/recreo-gw/Content/ResourcesByReportStatus/NEW", {
            signal: controller.signal,
          });
         // console.log(response?.data);
          isMounted && SetReports(response?.data.map( item => {
            const { fileName: name, ...rest } = item;
            return { name, ...rest }
           }));
        } catch (err) {
          if (err?.message !== "canceled") {
            console.log(err);
          }
        }
      };
  
      getReports();
  
      return () => {
        isMounted = false;
        controller.abort();
      };
    }, []);
    
    const tableProps = {
      columns: columns,
      data: reports
    };

  return (
   <section>
      <ContentHeader {...headerProps} />
      <section className="content">
        <div className="container-fluid">
          <CustomDataTable {...tableProps} />
        </div>
      </section>
    </section>
  )
}

export default ResourceReports
