import { useEffect, useState } from "react";
import Select from 'react-select'
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";
import { Link } from "react-router-dom";

const Competencies = () => {

    const headerProps = {
        title: "Competencias",
        parentName: "Home",
        parentLink: "/",
        btn: {
          to: "/competency/0",
          icon: "fa-cubes",
          text: " +"
        },
      };
    const [grades, SetGrades] = useState();
    const [subjects, SetSubjects] = useState();
    const [competencies, SetCompetencies] = useState();
    const axiosPrivate = useAxiosPrivate();

    const columns = [
        {
          name: "id",
          selector: (row) => row.competencyId,
          sortable: true,
          width: '150px',
        },
        {
            name: "Name",
            selector: (row) =>  row.name,
            sortable: true,
            wrap: true
          },
        {
            name: "Estatus",
            selector: (row) => row.isActive?"Activo":"Inactivo",
            sortable: true,
            width: '100px',
        },
        {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
            <Link to={`/expectedLearnings/${row.competencyId}`}  >
            <button type="button" className="btn btn-success btn-sm">
              <i className="fas fa-thumbtack" />
            </button>
            </Link>
            
            <Link to={`/competency/${row.competencyId}`}  >
            <button type="button" className="btn btn-primary btn-sm">
              <i className="fas fa-pencil-alt" />
            </button>
            </Link>
            
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
          width: '200px',
        },
      ];
    
      useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getCatalogs = async () => {
            try {
                const response = await axiosPrivate.get("/recreo-gw/Grades", {
                  signal: controller.signal,
                });
                isMounted && SetGrades(response?.data.map( item => {
                    const { gradeId: value, name: label, ...rest} = item;
                    return { value,label, ...rest }
                   }));
    
              } catch (err) {
                if (err?.message !== "canceled") {
                  console.log(err);
                }
              }
        }
    
        getCatalogs();
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);
    
      const handleGradeChange  = async (option) =>{
        //console.log(option);
        try {
            const response = await axiosPrivate.get("/recreo-gw/Subjects/Grade/"+option.value);
            SetSubjects(response?.data.map( item => {
                const { subjectId: value, name: label, ...rest } = item;
                return { value,label , ...rest}
               }));
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
      }
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        var object = {};
        formData.forEach(function (value, key) {
          object[key] = value;
        });
        //console.log(object);
        try {
          const response = await axiosPrivate.get(
            `/recreo-gw/competencies/Subject/${object.subject}`
          );
    
          //console.log(response);
    
          SetCompetencies(response.data)
          
        } catch (err) {
          if (!err?.response) {
            console.log("No Server Response");
          }
        }
      };
    
    
      const tableProps ={
        columns: columns,
        data :competencies
        }
    
      const customStyles = {
          control: (base, state) => ({
            ...base,
            width: '200px',
            'minWidth': '200px',
          }),
      };


  return (
    <div>
        <ContentHeader {...headerProps} />
        <section className="content">
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="card-body row">
                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="grade"
                    placeholder="Grado" 
                    options = {grades}
                    styles = {customStyles}
                    onChange={(e) => handleGradeChange(e)}
                    />
                </div>


                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="subject"  
                    placeholder="materia"
                    options = {subjects}
                    styles = {customStyles}
                    />
                </div>

                <button type="submit" className="btn btn-primary float-right">
                  Buscar
                </button>


                </div>

            </form>
        
            <CustomDataTable {...tableProps}/>
        </div>
        
        </section>


    </div>
  )
}

export default Competencies
