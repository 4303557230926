import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";

const School = (props) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: `${props.schoolId ? "Editar" : "Agregar"} escuela`,
      parentName: "Escuelas",
      parentLink: "/school",
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      var object = {};
      formData.forEach(function (value, key) {
        object[key] = value;
      });
      console.log(object);
      try {
       
          const rs = await axiosPrivate.post(
            "/recreo-gw/Schools",
            JSON.stringify(object)
          );
          navigate("/schools");
        
        
      } catch (err) {
        if (!err?.response) {
          console.log("No Server Response");
        }
      }
    };
  
  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
                {props.schoolId ? "Editar" : "Agregar"} Escuela
              </h3>
            </div>
            <div className="card-body row">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-school" />
                  </span>
                </div>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nombre de la escuela"
                />
              </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-school" />
                  </span>
                </div>
                <input
                  type="text"
                  name="schoolCode"
                  className="form-control"
                  placeholder="codigo (CCT)"
                />
              </div>
              
              <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope" />
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                </div>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="Ciudad"
                />
              </div>
              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                </div>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  placeholder="Estado"
                />
              </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                </div>
                <input
                  type="text"
                  name="schoolZone"
                  className="form-control"
                  placeholder="Zona Escolar"
                />
              </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-phone" />
                  </span>
                </div>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Telefono"
                />
              </div>

              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                </div>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Direccion"
                />
              </div>
              
              <div className="input-group mb-3 col-6">
              <input name="country" type="hidden" value="Mexico"/>
              </div>
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default School
