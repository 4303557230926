import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const Resources = () => {
    let { topic } = useParams();
    const [resources, SetResources] = useState();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: "Nuevos recursos",
      parentName: "Home",
      parentLink: "/",
      btn: {
        to: `/resource/${topic}/0`,
        icon: "fa-file-image",
        text: " +"
      },
    };
    const columns = [
      {
          name: "Nombre",
          selector: (row) => row.name,
          sortable: true,
        },
      {
        name: "Id",
        selector: (row) => row.fileId,
        sortable: true,
      },
      
      {
        name: "Description",
        selector: (row) =>row.description,
        sortable: true,
      },
      {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
          <Link to={`/resource/${row.topicId}/${row.fileId}`}   >
          <button type="button" className="btn btn-primary btn-sm">
            <i className="fas fa-pencil-alt" />
          </button>
          </Link>
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
        }
    ];
  
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
  
      const getResources = async () => {
        try {
          const response = await axiosPrivate.get("/recreo-gw/Content/GetResource/"+topic, {
            signal: controller.signal,
          });
          var resources =  response?.data.map(r => ({...r, topicId : topic}) )

          isMounted && SetResources(resources.map( item => {
            const { fileName: name, ...rest } = item;
            return { name, ...rest }
           }));
        } catch (err) {
          if (err?.message !== "canceled") {
            console.log(err);
          }
        }
      };
  
      getResources();
  
      return () => {
        isMounted = false;
        controller.abort();
      };
    }, []);
    
    const tableProps = {
      columns: columns,
      data: resources
    };

  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <CustomDataTable {...tableProps} />
      </div>
    </section>
  </section>
  )
}

export default Resources
