import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ContentHeader from '../ContentHeader';
import Select from 'react-select'

const Resource = () => {
    let {topic, id } = useParams();

    const headerProps = {
        title: `${id!=="new"? "Editar" : "Agregar"} recurso`,
        parentName: "Recursos",
        parentLink: "/nresources",
    };

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [resource, SetResource] = useState();
    const [selectedImage, setSelectedImage] = useState(null);

    var IMAGE_URL = "https://iusacomm.com/recreo-cdn/" + topic;

      

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getResource = async () => {
          try {
            const response = await axiosPrivate.post(`/recreo-gw/Content/GetResourcesByIds`,
            JSON.stringify([id]), 
            {signal: controller.signal, });
             console.log(response?.data);
            isMounted && SetResource(response?.data[0]);
            setSelectedImage(response?.data[0].mosaicRoute)

          } catch (err) {
            if (err?.message !== "canceled") {
              // console.log(err);
            }
          }
        };

        if(id!=="new"){
            getResource();
        }
        
        return () => {
          isMounted = false;
          controller.abort();
        };
    }, []);


    const handleReporResponseOnChange  = async (value,idx) =>{
      if (resource){

        let newReports = resource.reports;
        newReports[idx].response = value;
        newReports[idx].updatedAt = getCurrentDate("-");

        SetResource({ ...resource, reports:newReports });
      } 
    }
    const handleReporStatusOnChange  = async (value,idx) =>{
      
      if (resource){

        let newReports = resource.reports;
        newReports[idx].reportStatus = value;
        newReports[idx].updatedAt = getCurrentDate("-");

        SetResource({ ...resource, reports:newReports });
      } 

    }
   const getCurrentDate = (separator='') =>{

      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      
      return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
      }

    const handleSubmit = async (event) => {
        event.preventDefault();
         console.log(resource);
         
        try {
            if(id === "new"){
              
              resource.resourceType="otros";
             var response = await axiosPrivate.post(
                "/recreo-gw/Content/AddResource/"+topic,
                JSON.stringify(resource)
              );
              console.log(response)
            }else{
              await axiosPrivate.put(
                "/recreo-gw/Content/UpdateResource/"+resource.fileId,
                JSON.stringify(resource)
              );
            }
         
            navigate("/nresources");
        } catch (err) {
          if (!err?.response) {
            console.log("No Server Response");
          }
        }
      };
    
      const imageHandler = async (e) => {
          const file = e;
          if (/^image\//.test(file.type)) {
            console.log(file);
            const formData = new FormData();
            formData.append("image", file);
            
            const res =  await axiosPrivate.post(
                IMAGE_URL,
                formData,
              ); 
              console.log(res);

            const url = res?.data[0].fileRoute;
            setSelectedImage(url);
            SetResource({ ...resource, mosaicRoute:url });
          } else {
            alert('You could only upload images.');
          }

      }

  const statusOptions = [
    { value: 0, label: 'GENERAL' },
    { value: 1, label: 'NUEVO' },
    { value: 2, label: 'ESCUELA' }
  ]

  const reportsOptions = [
    { value: 'NEW', label: 'NUEVO' },
    { value: 'PENDING', label: 'PENDIENTE' },
    { value: 'ATTENDED', label: 'ATENDIDO' },
    { value: 'CLOSED', label: 'CERRADO' }
  ]
  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '300px',
      'minWidth': '300px',
    }),
  };

  const handleStatusChange  = async (status) =>{
    console.log (status);
    if (typeof status !== 'undefined' ) {
        if(!resource){
            SetResource({
                status: status.value
            });
        }else{
          SetResource({ ...resource, status:status.value });
        }
    }
    
        
    console.log (resource);

  }


  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id!=="new" ? "Editar" : "Agregar"} Recurso
              </h3>
            </div>
            <div className="card-body row">
              
            <div className="input-group mb-3 col-4">
            <label >Id: &nbsp; </label>
                {resource?.fileId }
                  
            </div>
            <div className="input-group mb-3 col-2">
            <label >TopicId: &nbsp; </label>
            {topic }
            </div>

                <div className="input-group mb-3 col-6">
                <label >EStatus: &nbsp; </label>
                
                <Select 
                    name="status"
                    placeholder="Estatus"
                    options = {statusOptions}
                    styles = {customStyles}
                    value={ statusOptions?.filter( status => status.value === resource?.status)}
                    onChange={(e) => handleStatusChange(e)}
                    />
                </div>

            

              <div className="input-group mb-3 col-12">
              <label className="col-3">Nombre del recurso: &nbsp; </label>
                <input
                  type="text"
                  name="fileName"
                  className="form-contro col-9"
                  placeholder="Los planetas"
                  required = {true}
                  value={resource?.fileName}
                  onChange ={(e) => SetResource({ ...resource, fileName: e.target.value })}
                  
                /> 
              </div>

              <div className="input-group mb-3 col-12">
              <label className="col-3">Descripción: &nbsp; </label>
                <input
                  type="text"
                  name="description"
                  className="form-control col-9"
                  required = {true}
                  placeholder="Video sobre los planetas"
                  value={resource?.description}
                  onChange ={(e) => SetResource({ ...resource, description: e.target.value })}
                />
              </div>

              <div className="input-group mb-3 col-12">
              <label className="col-3" >Link: &nbsp; </label>
                <input
                  type="text"
                  name="fileRoute"
                  className="form-control col-9"
                  required = {true}
                  placeholder="https://www.youtube.com/watch?v=hxC1uZxnSSQ"
                  value={resource?.fileRoute}
                  onChange ={(e) => SetResource({ ...resource, fileRoute: e.target.value })}
                />
              </div>
              
              <div className="input-group mb-3 col-12">
              <label  className="col-3">Etiquetas: &nbsp; </label>
                <input
                  type="text"
                  name="tags"
                  className="form-control col-9"
                  placeholder="Video, Planetas, Geaografia"
                  value={resource?.tags?.toString()}
                  onChange ={(e) => SetResource({ ...resource, tags: e.target.value.split(',').map(s => s.trim()) })}
                />
              </div>
              
              <div className="input-group mb-3 col-12">
              <label className="col-3">Imagen para el recurso: &nbsp; </label>
                <input
                  type="file"
                  name="mosaic"
                  className="col-9"
                  placeholder="Imagen del mosaico"
                  onChange ={(e) => imageHandler(e.target.files[0])}
                />
                {selectedImage && (
                        <div>
                        <img alt="not fount" width={"250px"} src={selectedImage} />

                        </div>
                    )}

              </div>

            
              <div className="input-group mb-3 col-12">
              <label >Reportes</label>
              </div>

                {resource?.reports ?
                resource.reports.map(function(report, id){
                    return (
                        
                        <div key={'re_'+id} className="mb-3 col-12">
                            
                        <blockquote class="quote-orange mt-0">
                        <h6> 

                        <div class="form-group row">
                        <label  class="col-sm-3 col-form-label">{report.createdBy}</label>
                        <label  class="col-sm-3 col-form-label">{report.createdAt}</label>
                        <div class="col-sm-3">
                        <Select 
                            name="reportStatus" 
                            placeholder="Estatus del reporte"
                            options = {reportsOptions}
                            styles = {customStyles}
                            value={reportsOptions?.filter( options => options.value === resource?.reports[id].reportStatus.toString())} 
                            onChange={(e) => handleReporStatusOnChange(e.value,id )}
                         
                            />
                        </div>
                        </div>
                        </h6>
                        
                        <p>{report.reportText}</p> 
                        <input
                            type="text"
                            name="response"
                            className="form-control"
                            placeholder="Respuesta"
                            value={resource?.reports[id].response}
                            onChange={(e) => handleReporResponseOnChange(e.target.value,id )}
                            />
                        </blockquote>
                        

                        </div>
                            
                        
                        
                    )
                }):"No hay Reportes"}
              
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default Resource
