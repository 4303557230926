import { Link } from "react-router-dom";

const GeneralInfo = (props) => {
  const bgColors = ["bg-success", "bg-warning", "bg-danger"];

  const getBgColor = (result) => {
    var lResult = Math.round((result  + Number.EPSILON) * 10) / 100;

    var color = 1;
    if (lResult >= 8) {
      color = 0;
    } else if (lResult <= 5.9) {
      color = 2;
    }

    return bgColors[color];
  };

  return (
    <div className="row">
      {props.schools.map((school, i) => (

        <div key={i} className="col-lg-2 col-6">
          <div className={`small-box ${getBgColor(school?.quizProgress)}`}>
            <div className="inner">
              <h3 style={{ fontSize: "1.5em" }}>{school?.schoolCode}</h3>
              <p style={{ marginBottom: "0" }}>
                Grupos: {school?.groups} <br />
                Maestros: {school?.teachers} <br />
                Estudiantes: {school?.students}
              </p>
            </div>
            <div className="icon">
              <i className="fa fa-school" />
            </div>
            <a href={`${school?.instance}Dashboard?schoolId=${school?.schoolId}&super=50937`} target ="_blank" className="small-box-footer">
              Mas info <i className="fas fa-arrow-circle-right" />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GeneralInfo;
