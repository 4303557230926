import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const RequireAuth = ({allowedRoles}) => {
  const { auth } = useAuth();
  const location = useLocation();

  
    const authInfo = localStorage.getItem('app_token');
    if (authInfo) {
      const data = JSON.parse(authInfo);
      auth.accessToken = data?.token;
      auth.roles = [data?.role];
    }

   //console.log(auth?.user);
  //  console.log(auth?.roles);
  //  console.log(allowedRoles);
  return(
    auth?.roles?.find(role => allowedRoles?.includes(role)) 
    ? <Outlet />
    : auth?.accessToken
      ? <Navigate to="/unauthorized" state={{ from: location }} replace />
      : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
