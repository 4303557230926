import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";

const RESET_URL = "/recreo-id/Account/ResetPassword";

const Reset = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        RESET_URL,
        {params: { email: user }}
      );
      console.log(response?.data);
      //console.log(response);
     
      setUser("");
      setErrMsg("Hemos enviado su password al correo electronico");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else if (err.response?.status === 404) {
        setErrMsg("User not found");
      } else {
        setErrMsg("Reset Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="hold-transition login-page">
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <div className="login-box">
        {/* /.login-logo */}
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="/" className="h1 text-primary ">
              <b>RECREO</b>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Restablecer contraseña</p>

            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <button type="submit" className="btn btn-primary btn-block">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
            <p>
              <span className="line">
                <Link to="/login">Login</Link>
              </span>
            </p>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
    </section>
  );
};

export default Reset;
