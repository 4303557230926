import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const NewQuestions = () => {
    const [questions, SetQuestions] = useState();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: "Nuevos reactivos",
      parentName: "Home",
      parentLink: "/"
    };
    const columns = [
      {
          name: "creado por",
          selector: (row) => row.createdBy,
          sortable: true,
        },
      {
        name: "Question Id",
        selector: (row) => row.name,
        sortable: true,
      },
      
      {
        name: "Topic Id",
        selector: (row) =>row.topicId[0],
        sortable: true,
      },
      {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
            <Link to={`/question/${row.topicId[0].toString()}/${row.name}`}   >
            <button type="button" className="btn btn-primary btn-sm">
              <i className="fas fa-pencil-alt" />
            </button>
            </Link>
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
        }
    ];
  
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
  
      const getQuestions = async () => {
        try {
          const response = await axiosPrivate.get("/recreo-gw/Questions/GetQuestionsToApprove", {
            signal: controller.signal,
          });
          isMounted && SetQuestions(response?.data.map( item => {
            const { id: name, ...rest } = item;
            return { name, ...rest }
           })); 
        } catch (err) {
          if (err?.message !== "canceled") {
            console.log(err);
          }
        }
      };
  
      getQuestions();
  
      return () => {
        isMounted = false;
        controller.abort();
      };
    }, []);
    
    const tableProps = {
      columns: columns,
      data: questions
    };
  
    return (
      <section>
        <ContentHeader {...headerProps} />
        <section className="content">
          <div className="container-fluid">
            <CustomDataTable {...tableProps} />
          </div>
        </section>
      </section>
    )
}

export default NewQuestions
