import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "./dataTable.css";

const CustomDataTable = (props) => {
  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
 //console.log(props);
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    
    <>
      <div className="input-group input-group-sm col-lg-4">
        <input
          className="form-control"
          id="search"
          type="text"
          placeholder="Filter By Name"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
        />
        <span className="input-group-append">
          <button
            type="button"
            className="btn btn-danger btn-flat"
            onClick={onClear}
          >
            X
          </button>
        </span>
      </div>
    </>
  );

	const filteredItems = props.data?.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
     
		 <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);

	}, [filterText, resetPaginationToggle]);

  return (
      <DataTable
          columns={props.columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          selectableRows
          persistTableHead
        />   
    
  
  );
};

export default CustomDataTable;
