import { useEffect, useState } from "react";
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";
import { Link } from "react-router-dom";

const Tags = () => {
    const headerProps = {
        title: "Tags",
        parentName: "Home",
        parentLink: "/",
        btn: {
          to: "/tag/0",
          icon: "fa-tag",
          text: " +"
        },
      };
    const [tags, SetTags] = useState();
    const axiosPrivate = useAxiosPrivate();

  const columns = [
    {
      name: "id",
      selector: (row) => row.tagId,
      sortable: true,
    },
    {
        name: "Name",
        selector: (row) =>  row.name,
        sortable: true,
      },
    {
        name: "type",
        selector: (row) => row.tagType,
        sortable: true,
    },
    {
        name: "Class",
        selector: (row) => row.tagClass,
        sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    
    {
      name: "Actions",
      selector: (row) => 
      <div className="btn-group">
        <Link to={`/tag/${row.tagId}`}  >
        <button type="button" className="btn btn-primary btn-sm">
          <i className="fas fa-pencil-alt" />
        </button>
        </Link>
        
        <button type="button" className="btn btn-danger btn-sm">
          <i className="far fa-trash-alt" />
        </button>
      </div>,
      sortable: false,
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getTags = async () => {
      try {
        const response = await axiosPrivate.get("/recreo-gw/Tags", {
          signal: controller.signal,
        });
        //console.log(response?.data);
        isMounted && SetTags(response?.data);
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    getTags();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);


  const tableProps ={
    columns: columns,
    data :tags
    }
  return (
    <div>
      <ContentHeader {...headerProps} />
      <CustomDataTable {...tableProps}/>


    </div>
  )
}

export default Tags
