import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useRef } from "react";
import Select from 'react-select'

const Question = () => {
    let { id } = useParams();
    let { topicId } = useParams();
    const customStyles = {
      control: (base, state) => ({
        ...base,
        width: '300px',
        'minWidth': '300px',
      }),
  };
    const headerProps = {
            title: `${id !=="new" ? "Editar" : "Agregar"} reactivo`,
            parentName: "Reactivos",
            parentLink: "/nquestions",
          };
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [question, SetQuestion] = useState();
    const [tags, SetTags] = useState();
    const [questionTags, SetQuestionTags] = useState();
    const [newTopicList, SetNewTopicList] = useState([''+topicId]);

    var IMAGE_URL = "https://iusacomm.com/recreo-cdn/" + topicId;
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getTags = async () =>{
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Tags/Type?tagType=QUESTION`, {
              signal: controller.signal,
            });
            //// //console.log(response?.data);
            isMounted && SetTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
              // //console.log(err);
            }
          }
        };
    
        const getQuestion = async () => {
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Questions/${id}`, {
              signal: controller.signal,
            });
            isMounted && SetQuestion(response?.data);
            SetNewTopicList(response?.data?.topicId ? response?.data?.topicId.join(', '): topicId)
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
          try {
            const response = await axiosPrivate.get(`/recreo-gw/QuestionTags/Question/${id}`, {
              signal: controller.signal,
            });
            isMounted && SetQuestionTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
        };

        getTags();

        if(id !=="new"){
            getQuestion();
        }
        
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);

      const qbRef = useRef(); 
      const fbRef = useRef(); 
      const a0 = useRef(); 
      const a1 = useRef(); 
      const a2 = useRef(); 
      const a3 = useRef(); 

      const imageHandler = (e) => {
        const editor = e.componentProps.ref.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
          const file = input.files[0];
          if (/^image\//.test(file.type)) {
            //console.log(file);
            const formData = new FormData();
            formData.append("image", file);
            
            const res =  await axiosPrivate.post(
                IMAGE_URL,
                formData,
              ); 

            const url = res?.data[0].fileRoute;
            editor.insertEmbed(editor.getSelection(), "image", url);
          } else {
            alert('You could only upload images.');
          }
        };
      }
      const container = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', "strike"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['image', "link",],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
      ]

      const options = [
        { value: 0, label: 'GENERAL' },
        { value: 1, label: 'NUEVO' },
        { value: 2, label: 'ESCUELA' }
      ]

      const reportsOptions = [
        { value: 'NEW', label: 'NUEVO' },
        { value: 'PENDING', label: 'PENDIENTE' },
        { value: 'ATTENDED', label: 'ATENDIDO' },
        { value: 'CLOSED', label: 'CERRADO' }
      ]
 
      const qbModules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:qbRef} })
          }},}), []);
      const fbModules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:fbRef} })
          }
        },
      }), []);

      const a0Modules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:a0} })
          }
        },
      }), []);

      const a1Modules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:a1} })
          }
        },
      }), []);

      const a2Modules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:a2} })
          }
        },
      }), []);

      const a3Modules = useMemo(() => ({
        toolbar: {
          container:container,
          handlers: {
            image: (val) => imageHandler({ val, componentProps: {ref:a3} })
          }
        },
      }), []);


      const handleStatus  = async (e) =>{
        //console.log (e);
        if (typeof e !== 'undefined') {
            if(!question){
                SetQuestion({
                    status:e.value
                });
            }else{
                SetQuestion({ ...question, status: e.value })
            }
        }
        
            
        //console.log (question);

      }

      const handleQuillOnChange  = async (value,attr) =>{
        if (question){
            question[attr] = value;
            SetQuestion(question);
        } 
      }
      const handleAnswerOnChange  = async (value,idx) =>{
        //console.log(question)
       

        if (question){

          if(!question.answers){
            question.answers = new Array(4);
          }

          if(typeof question.answers[idx] === 'undefined'){
            question.answers[idx] = {fraction: 0, answerText : "" };
          }

//console.log(question.answers);
 
           if(id === "new"){
            //console.log(value,idx)
            idx === 0
            ?question.answers[idx].fraction = 1
            :question.answers[idx].fraction = 0
           }

           
          
            question.answers[idx].answerText = value;
            SetQuestion(question);
        } 
        //console.log(question)
      }

      const handleReporResponseOnChange  = async (value,idx) =>{
        if (question){

          let newReports = question.reports;
        newReports[idx].response = value;
        newReports[idx].updatedAt = getCurrentDate("-");

        SetQuestion({ ...question, reports:newReports });

            // question.reports[idx].updatedAt = getCurrentDate("-");
            // question.reports[idx].response = value;
            // SetQuestion(question);
        } 
      }
      const handleReporStatusOnChange  = async (value,idx) =>{
        if (question){

          let newReports = question.reports;
          newReports[idx].reportStatus = value;
          newReports[idx].updatedAt = getCurrentDate("-");
  
          SetQuestion({ ...question, reports:newReports });
            // question.reports[idx].updatedAt = getCurrentDate("-");
            // question.reports[idx].reportStatus = value.toString();
            // SetQuestion(question);
        } 
      }
     const getCurrentDate = (separator='') =>{

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
        }
     

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        var object = {};
        formData.forEach(function (value, key) {
          object[key] = value;
        });
         //console.log(object);
         //console.log(question);
        try {
            
            if(id === "new"){
              question.topicId = newTopicList;
              let result = await axiosPrivate.post(
                "/recreo-gw/Questions",
                JSON.stringify(question)
              );
              let tagIds = questionTags.map(x => x.tagId);
              await axiosPrivate.post(
                `/recreo-gw/QuestionTags/Tags/${result?.data.id}`,
                JSON.stringify(tagIds)
              );
            }else{
              question.topicId = newTopicList;
              await axiosPrivate.put(
                `/recreo-gw/Questions/${question.id}`,
                JSON.stringify(question)
              );
              let tagIds = questionTags.map(x => x.tagId);
              await axiosPrivate.put(
                `/recreo-gw/QuestionTags/Tags/${question.id}`,
                JSON.stringify(tagIds)
              );
            }

         
          navigate("/questions/"+newTopicList[0]); //5e615f1a3d242600294fa1bf
          
          
        } catch (err) {
          if (!err?.response) {
             //console.log("No Server Response");
          }
        }


      };

  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id !=="new" ? "Editar" : "Agregar"} reactivo
              </h3>
            </div>
            <div className="card-body row">
              
              <div className="input-group mb-3 col-4">
                <label >Question Id: &nbsp; </label>
                {question?.id}
                  
                </div>
                <div className="input-group mb-3 col-6">
                <label >Estatus: &nbsp;</label>
                  
                  <Select 
                  id="QStattus"
                  name="Status" 
                  options={options}
                  styles = {customStyles}
                  value={ options?.filter( option => option.value === question?.status)}
                  onChange ={(e) => handleStatus(e)}
                  />
                
                </div>

                {/* <div className="input-group mb-3 col-2">
                {question?.topicId ? question?.topicId.join(', '): topicId }
                  
                </div> */}

                  <div className="input-group mb-3 col-2">
                  <label >Topic Id: &nbsp; </label>
                  
                  <input
                    id="topicId"
                    type="text"
                    name="topicId"
                    className="form-control"
                    
                  styles = {customStyles}
                    placeholder="topicId"
                    value={newTopicList }
                    onChange ={(e) => SetNewTopicList(e.target.value.split(','))}
                    
                  /> 
                </div>

                <div className="input-group mb-3 col-8">
                <label >Clasificación: &nbsp;</label>
                    <Select 
                    name="tags"  
                    placeholder="categorias"
                    isMulti
                    options = {tags}
                    getOptionLabel={(option) => `${option.tagClass}-${option.name}`}
                    getOptionValue={(option) => option.tagId}
                    value={ tags?.filter( tag => questionTags?.some(qt => qt.tagId === tag.tagId))}
                    styles = {customStyles}
                    onChange ={(e) => SetQuestionTags(e)}
                    />
                </div>

              <div className="input-group mb-3 col-12">
              <label >Base del reactivo</label>
                <ReactQuill 
                id= "qb"
                theme="snow"
                ref={qbRef}
                modules = {qbModules}
                value={question?.questionBase}
                onChange={(e) => handleQuillOnChange(e,'questionBase')} 
                style={{ width: '100%', height: '100%' }}
                />

              </div>
              <div style={{ width: '100%', height: 70 }}></div>

              <div className="input-group mb-3 col-12">
              <label >Feedback</label>
                <ReactQuill 
                id = "fb"
                theme="snow" 
                ref={fbRef}
                modules = {fbModules}
                value={question?.feedback}
                onChange={(e) => handleQuillOnChange(e,'feedback')} 
                style={{ width: '100%', height: '100%' }}
                />

              </div>
              <div style={{ width: '100%', height: 70 }}></div>
              
              
                {/* {question?.answers.map(function(answer, id){
                    return (<li key={id}>{answer.answerText}</li>)
                })} */}
              <div className="input-group mb-3 col-12">
              <label >Respuestas</label>
              </div>
              
              <div className="col-6">
                  <div  className="input-group mb-3 col-12">
                    <ReactQuill 
                    theme="snow"  
                    ref={a0}
                    modules = {a0Modules}
                    value={question?.answers
                      ? question?.answers[0]?.answerText
                      : null}
                    onChange={(e) => handleAnswerOnChange(e,0 )} 
                    style={{ width: '100%', height: '100%' }}
                    />
                    <div className="ribbon-wrapper">
                        <div className="ribbon bg-success">
                            "Correcto"
                        </div>
                    </div>
                  </div>
                  
              </div>

              <div className="col-6">
                  <div  className="input-group mb-3 col-12">
                    <ReactQuill 
                    theme="snow"  
                    ref={a1}
                    modules = {a1Modules}
                    value={question?.answers
                      ? question?.answers[1]?.answerText
                      : null}
                    onChange={(e) => handleAnswerOnChange(e,1 )} 
                    style={{ width: '100%', height: '100%' }}
                    />
                    <div className="ribbon-wrapper">
                        <div className="ribbon bg-danger">
                            "Falso"
                        </div>
                    </div>
                  </div>
                  
              </div>

              <div className="col-6">
                  <div  className="input-group mb-3 col-12">
                    <ReactQuill 
                    theme="snow"  
                    ref={a2}
                    modules = {a2Modules}
                    value={question?.answers
                      ? question?.answers[2]?.answerText
                      : null}
                    onChange={(e) => handleAnswerOnChange(e,2 )} 
                    style={{ width: '100%', height: '100%' }}
                    />
                    <div className="ribbon-wrapper">
                        <div className="ribbon bg-danger">
                            "Falso"
                        </div>
                    </div>
                  </div>
                  
              </div>

              <div className="col-6">
                  <div  className="input-group mb-3 col-12">
                    <ReactQuill 
                    theme="snow"  
                    ref={a3}
                    modules = {a3Modules}
                    value={question?.answers
                      ? question?.answers[3]?.answerText
                      : null}
                    onChange={(e) => handleAnswerOnChange(e,3 )} 
                    style={{ width: '100%', height: '100%' }}
                    />
                    <div className="ribbon-wrapper">
                        <div className="ribbon bg-danger">
                            "Falso"
                        </div>
                    </div>
                  </div>
                  
              </div>


              <div className="input-group mb-3 col-12">
              <label >Reportes</label>
              </div>

                {question?.reports ?
                question.reports.map(function(report, id){
                    return (
                        
                        <div key={'re_'+id} className="mb-3 col-12">
                            
                        <blockquote class="quote-orange mt-0">
                        <h5> 

                        <div class="form-group row">
                        <label  class="col-sm-3 col-form-label">{report.createdAt}</label>
                        <div class="col-sm-3">
                        <Select 
                            name="reportStatus" 
                            placeholder="Estatus del reporte"
                            options = {reportsOptions}
                            styles = {customStyles}
                            value={reportsOptions?.filter( options => options.value === question?.reports[id].reportStatus.toString())} 
                            onChange={(e) => handleReporStatusOnChange(e.value,id )}
                         
                            />
                        </div>
                        </div>
                        </h5>
                        
                        <p>{report.reportText}</p> 
                        <input
                            type="text"
                            name="response"
                            className="form-control"
                            placeholder="Respuesta"
                            value={question?.reports[id].response}
                            onChange={(e) => handleReporResponseOnChange(e.target.value,id )}
                            />
                        </blockquote>
                        

                        </div>
                            
                        
                        
                    )
                }):"No hay Reportes"}
              
              <div className="input-group mb-3 col-12">
              </div>

              {/* <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tag" />
                  </span>
                </div>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nombre de la etiqueta"
                  value={newTagName}
                  onChange ={(e) => SetNewTagName(e.target.value)}
                />
              </div>
              
              
              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-paragraph" />
                  </span>
                </div>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  placeholder="Descripcion"
                  value={newTagDesc}
                  onChange ={(e) => SetNewTagDesc(e.target.value)}
                />
              </div>
              
              <div className="input-group mb-3 col-6">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-list" />
                  </span>
                </div>
                <input
                  type="text"
                  name="ids"
                  className="form-control"
                  placeholder='topic o question ids separados por ","'
                  value={newTagList}
                  onChange ={(e) => SetNewTagList(e.target.value)}
                />
              </div> */}
              
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default Question
