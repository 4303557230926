import { useState, useEffect } from "react";
import Select from 'react-select'
import Switch from "react-switch";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";

const Topic = () => {

    let { id } = useParams();
    //const [headerProps, SetHeaderProps] = useState();
    const headerProps = {
            title: `${id>0 ? "Editar" : "Agregar"} Tema`,
            parentName: "Temas",
            parentLink: "/topics",
          };
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [topic, SetTopic] = useState();
    const [subjects, SetSubjects] = useState();
    const [blocks, SetBlocks] = useState();
    const [grades, SetGrades] = useState();
    const [competencies, SetCompetencies] = useState();
    const [expectedLearnings, SetExpectedLearnings] = useState();
    const [tags, SetTags] = useState();
    const [topicTags, SetTopicTags] = useState();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getTopic = async () => {
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Topics/${id}`, {
              signal: controller.signal,
            });
             console.log(response?.data);
            isMounted && SetTopic(response?.data);
            handleGradeChange({value:response?.data.expectedLearning.competency.subject.grade.gradeId})
            handleSubjectChange({value:response?.data.expectedLearning.competency.subject.subjectId})
            handleCompetencyChange({value:response?.data.expectedLearning.competency.competencyId})

          } catch (err) {
            if (err?.message !== "canceled") {
              // console.log(err);
            }
          }
          try {
            const response = await axiosPrivate.get(`/recreo-gw/TopicTags/Topic/${id}`, {
              signal: controller.signal,
            });
            isMounted && SetTopicTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
        };

        const getTags = async () =>{
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Tags/Type?tagType=Topic`, {
              signal: controller.signal,
            });
            //// //console.log(response?.data);
            isMounted && SetTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
              // //console.log(err);
            }
          }
        };

        const getCatalogs = async () => {
            try {
                const bloksResposnse = await axiosPrivate.get("/recreo-gw/Blocks", {
                    signal: controller.signal,
                  });
                
                  isMounted && SetBlocks(bloksResposnse?.data
                //     .map( item => {
                //     const { blockId: value, name: label, ...rest} = item;
                //     return { value,label, ...rest }
                //    })
                   );
                   
                   const response = await axiosPrivate.get("/recreo-gw/Grades", {
                    signal: controller.signal,
                  });
                   SetGrades(response?.data.map( item => {
                      const { gradeId: value, name: label, ...rest} = item;
                      return { value,label, ...rest }
                     }));
             
  
            } catch (err) {
              if (err?.message !== "canceled") {
                // console.log(err);
              }
            }
          };

          getCatalogs();
          getTags();

        if(id>0){
            getTopic();
        }
        
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);

      const handleSubmit = async (event) => {

        event.preventDefault();
       
         console.log(topic);
         if(!topic.topicBlocks||!topic.expectedLearningId){
            alert("Todos los campos son requeridos");
            return;
         }
        try {

           
            delete topic.expectedLearning;
            topic.topicBlocks.forEach(function(v){ delete v.school });
             console.log(topic);
            
            if(id === "0"){
             var response = await axiosPrivate.post(
                "/recreo-gw/Topics",
                JSON.stringify(topic)
              );
              console.log(response)
              let tagIds = topicTags.map(x => x.tagId);
              await axiosPrivate.post(
                `/recreo-gw/TopicTags/Tags/${response?.data.topicId}`,
                JSON.stringify(tagIds)
              );
            }else{
              topic.topicBlocks.forEach(function(v){ delete v.block });
              await axiosPrivate.put(
                "/recreo-gw/Topics/"+topic.topicId,
                JSON.stringify(topic)
              );
              let tagIds = topicTags.map(x => x.tagId);
              await axiosPrivate.put(
                `/recreo-gw/TopicTags/Tags/${topic.topicId}`,
                JSON.stringify(tagIds)
              );
            }

         
            navigate("/topics");
          
          
        } catch (err) {
          if (!err?.response) {
            console.log("No Server Response");
          }
        }


      };
      const customStyles = {
        control: (base, state) => ({
          ...base,
          width: '300px',
          'minWidth': '300px',
        }),
    };

    
    const handleTopicBlocks  = async (blocks) =>{
        console.log (blocks);
        if (typeof blocks !== 'undefined' && blocks.length > 0) {
            if(!topic){
                SetTopic({
                    topicBlocks:blocks
                });
            }else{
                SetTopic({ ...topic, topicBlocks: blocks })
            }
        }
        
            
        console.log (topic);

      }

      const handleGradeChange  = async (option) =>{
        //console.log(option);
        try {
            const response = await axiosPrivate.get("/recreo-gw/Subjects/Grade/"+option.value);
            SetSubjects(response?.data.map( item => {
                const { subjectId: value, name: label, ...rest } = item;
                return { value,label , ...rest}
               }));
               console.log(topic,id)
            if(id==='0' && typeof topic === 'undefined'){
                console.log("hey")
                SetTopic({expectedLearning:{competency:{subject:{grade:{gradeId:option.value}}}}});
            }else if(topic) {
                console.log("hey2")
                SetTopic({ ...topic, expectedLearning: {competency:{subject:{grade:{gradeId:option.value}}}} })
            }

          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
      }

      const handleSubjectChange  = async (option) =>{
        //console.log(option);
        try {
            const response = await axiosPrivate.get("/recreo-gw/Competencies/Subject/"+option.value);
            SetCompetencies(response?.data.map( item => {
                const { competencyId: value, name: label, ...rest } = item;
                return { value,label , ...rest}
               }));
            if(topic?.expectedLearning){
                topic.expectedLearning.competency.subject.subjectId = option.value;
                SetTopic(topic)
            }
            
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
      }

      const handleCompetencyChange  = async (option) =>{
        //console.log(option);
        try {
            const response = await axiosPrivate.get("/recreo-gw/ExpectedLearnings/Competency/"+option.value);
            SetExpectedLearnings(response?.data.map( item => {
                const { expectedLearningId: value, name: label, ...rest } = item;
                return { value,label , ...rest}
               }));
            if(topic?.expectedLearning){
                topic.expectedLearning.competency.competencyId = option.value;
                SetTopic(topic);
            }
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
      }
      
      const handleSwitchChange = nextChecked => {
        console.log(nextChecked);
        if(!topic){
            console.log("sdasd");
            SetTopic({
                isActive:nextChecked
            });
        }else{
            SetTopic({ ...topic, isActive: nextChecked })
        }
        //console.log(topic);
      };

  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id>0 ? "Editar" : "Agregar"} Tema
              </h3>
            </div>
            <div className="card-body row">
              
            <div className="input-group mb-3 col-12">
            <label >Id: &nbsp; </label>
                {topic?.topicId }
                  
            </div>
            <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="grade"
                    placeholder="Grado"
                    options = {grades}
                    styles = {customStyles}
                    value={ grades?.filter( grade => grade.value === 
                      topic?.expectedLearning?.competency.subject.grade.gradeId)}
                    onChange={(e) => handleGradeChange(e)}
                    />
            </div>
            <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="block"  
                    placeholder="bloques"
                    isMulti
                    options = {blocks}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.blockId}
                    value={ blocks?.filter( block => topic?.topicBlocks?.some(tb => tb.blockId === block.blockId))}
                    styles = {customStyles}
                    onChange ={(e) => handleTopicBlocks(e)}
                    />
                </div>
                <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="subject"  
                    placeholder="materia"
                    options = {subjects}
                    value={ subjects?.filter( subject => subject.value === 
                      topic?.expectedLearning?.competency.subject.subjectId)}
                    styles = {customStyles}
                    onChange={(e) => handleSubjectChange(e)}
                    />
                </div>

                <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="competency"  
                    placeholder="Competencia"
                    options = {competencies}
                    value={ competencies?.filter( competency => competency.value === 
                      topic?.expectedLearning?.competency.competencyId)}
                    styles = {customStyles}
                    onChange={(e) => handleCompetencyChange(e)}
                    />
                </div>
                <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="expectedLearning"  
                    placeholder="AprendizajeEsperado"
                    options = {expectedLearnings}
                    value={ expectedLearnings?.filter( expectedLearning => expectedLearning.value === topic?.expectedLearningId)}
                    styles = {customStyles}
                    onChange ={(e) => SetTopic({ ...topic, expectedLearningId: e.value })}
                    />
                </div>
                <div className="input-group mb-3 col-4">
                <label >Activo: &nbsp; </label>
                
                <Switch 
                    onChange={handleSwitchChange} 
                    checked={topic?.isActive ? true: false } 
                    // height={30}
                    // handleDiameter={30}
                    />
                </div>

            

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-cubes" />
                  </span>
                </div>
                <input
                  id="name"
                  type="textarea"
                  name="name"
                  className="form-control"
                  placeholder="Tema"
                  required = {true}
                  value={topic?.name}
                  onChange ={(e) => SetTopic({ ...topic, name: e.target.value })}
                  
                /> 
              </div>

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tag" />
                  </span>
                </div>
                <input
                  type="textarea"
                  name="description"
                  className="form-control"
                  required = {true}
                  placeholder="Descripcion"
                  value={topic?.description}
                  onChange ={(e) => SetTopic({ ...topic, description: e.target.value })}
                />
              </div>

              <div className="input-group mb-3 col-8">
                <label >Clasificación: &nbsp;</label>
                    <Select 
                    name="tags"  
                    placeholder="categorias"
                    isMulti
                    options = {tags}
                    getOptionLabel={(option) => `${option.tagClass}-${option.name}`}
                    getOptionValue={(option) => option.tagId}
                    value={ tags?.filter( tag => topicTags?.some(qt => qt.tagId === tag.tagId))}
                    styles = {customStyles}
                    onChange ={(e) => SetTopicTags(e)}
                    />
                </div>

              
              
            

              
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default Topic
