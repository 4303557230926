import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ContentHeader from "./ContentHeader";
import GeneralInfo from "./dashboard/GeneralInfo";
import MainCharts from "./dashboard/MainCharts";

const Home = () => {
  const [schools, SetSchools] = useState();
  const axiosPrivate = useAxiosPrivate();
  const headerProps = {
    title: "Dashboard",
    parentName: "Home",
    parentLink: "/",
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSchools = async () => {
      try {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();
        let yesterday =
          date.getFullYear() +
          "-" +
          (mm < 10 ? "0" + mm : mm) +
          "-" +
          (dd < 10 ? "0" + dd : dd);
          let endpoint =""

          const uInfo = localStorage.getItem('uInfo');
          if (uInfo) {
            const data = JSON.parse(uInfo);
           if(data.zones[0] === "All"){
            endpoint = "/recreo-gw/SchoolData/"+yesterday;
           }else {
             endpoint = "/recreo-gw/SchoolData/Zone/"+data.zones[0]+"/"+yesterday
           }

          }

        const response = await axiosPrivate.get(
          endpoint,
          {
            signal: controller.signal,
          }
        );

        isMounted && SetSchools(response?.data);
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    getSchools();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <section>
      <ContentHeader {...headerProps} />

      <section className="content">
        <div className="container-fluid">
          {schools?.length ? (
            <>
              <GeneralInfo schools={schools} />
              <MainCharts schools={schools} />
            </>
          ) : (
            <p>No data to display</p>
          )}
        </div>
      </section>
    </section>
  );
};

export default Home;
