import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";

const User = (props) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const headerProps = {
    title: `${props.userId ? "Editar" : "Agregar"} usuario`,
    parentName: "Usuarios",
    parentLink: "/users",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    object.zones = [object.zone];
    console.log(object);
    try {
      const response = await axiosPrivate.post(
        "/recreo-id/Account",
        JSON.stringify({ email: object.email, role: object.role })
      );

      console.log(response);

      if (response) {
        const rs = await axiosPrivate.post(
          "/recreo-gw/Users",
          JSON.stringify({ userInfo: object })
        );
        navigate("/users");
      }
      
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      }
    }
  };

  return (
    <section>
      <ContentHeader {...headerProps} />
      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">
                  {props.userId ? "Editar" : "Agregar"} usuario
                </h3>
              </div>
              <div className="card-body row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope" />
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>

                <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user" />
                    </span>
                  </div>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Nombre"
                  />
                </div>

                <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user-plus" />
                    </span>
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Apellido"
                  />
                </div>

                <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-phone" />
                    </span>
                  </div>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Telefono"
                  />
                </div>

                <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="zone"
                    className="form-control"
                    placeholder="Zona"
                  />
                </div>
                <div className="input-group mb-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-check"></i>
                    </span>
                  </div>
                  <select name="role" className="custom-select rounded-0">
                    <option>Supervisor</option>
                    <option>Administrator</option>
                  </select>
                </div>
                <div className="input-group mb-3 col-6">
                <input name="avatarImg" type="hidden" value="https://iusacomm.com/recreo-cdn/8961127d-1ccb-4ef0-b6f0-93ba384e52ea.png"></input>
                </div>
                
                <button type="submit" className="btn btn-primary float-right">
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </section>
  );
};

export default User;
