import { Link } from "react-router-dom"

const ContentHeader = (props) => {
  return (
    <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-4">
              <h1 className="m-0">{props.title}</h1>
            </div>
            <div className="col-sm-2 float-sm-right">
            {props.btn
              ?<Link to = {props.btn.to} className="btn btn-outline-primary btn-block "><i className={`fa ${props.btn.icon}`}> {props.btn.text}</i></Link>
              : ""
            }
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to={props.parentLink}>{props.parentName}</Link></li>
                <li className="breadcrumb-item active">{props.title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  )
}

ContentHeader.defautProps ={
    title:"No title",
    parentName: "Home",
    parentLink: "/"

}

export default ContentHeader
