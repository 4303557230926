import React from "react";
import { Link } from "react-router-dom";

const AdminMenu = () => {
  return (
    <>
      <li className="nav-header">ADMIN</li>
      <li className="nav-item">
        <Link to="/users" className="nav-link">
          <i className="nav-icon fas fa-users" />
          <p>Usuarios</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/schools" className="nav-link">
          <i className="nav-icon fas fa-school" />
          <p>Escuelas</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/tags" className="nav-link">
          <i className="nav-icon fas fa-tags" />
          <p>Tags</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/competencies" className="nav-link">
          <i className="nav-icon fas fa-cubes" />
          <p>Competencias</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/topics" className="nav-link">
          <i className="nav-icon fas fa-cubes" />
          <p>Temas</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/qreports" className="nav-link">
          <i className="nav-icon fas fa-flag" />
          <p>Reportes de reactivos</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/nquestions" className="nav-link">
          <i className="nav-icon fas fa-question" />
          <p>Nuevos reactivos</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/resource_reports" className="nav-link">
          <i className="nav-icon fas fa-flag" />
          <p>Reportes de recursos</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/nresources" className="nav-link">
          <i className="nav-icon fas fa-file-image" />
          <p>Nuevos recursos</p>
        </Link>
      </li>
    </>
  );
};

export default AdminMenu;
