import { useEffect, useState } from "react";
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";
import { Link } from "react-router-dom";

const Users = () => {
  const [users, SetUSers] = useState();
  const axiosPrivate = useAxiosPrivate();
  const headerProps = {
    title: "Usuarios",
    parentName: "Home",
    parentLink: "/",
    btn: {
      to: "/user",
      icon: "fa-user-plus",
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "zone",
      selector: (row) => row.zones[0],
      sortable: true,
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/recreo-gw/Users", {
          signal: controller.signal,
        });
        //console.log(response?.data.map((item) => item.userInfo));
        isMounted && SetUSers(response?.data.map((item) => item.userInfo));
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const tableProps = {
    columns: columns,
    data: users,
  };

  return (
    <section>
      <ContentHeader {...headerProps} />
      <section className="content">
        <div className="container-fluid">
          <CustomDataTable {...tableProps} />
        </div>
      </section>
    </section>
  );
};

export default Users;
