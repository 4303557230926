import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const QuestionReports = () => {

  const [reports, SetReports] = useState();
  const axiosPrivate = useAxiosPrivate();
  const headerProps = {
    title: "Reactivos reportados",
    parentName: "Home",
    parentLink: "/"
  };
  const columns = [
    
    {
      name: "Question Id",
      selector: (row) => row.name,
      sortable: true,
      width: '280px',
      wrap: true
    },
    {
      name: "Reporte",
      selector: (row) => row.reports.filter(r => {return r.reportStatus === "NEW"})[0].reportText,
      sortable: true,
      width: '420px',
      wrap: true
    },
    {
      name: "Topic Id",
      selector: (row) =>row.topicId.toString(),
      sortable: true,
      width: '180px',
    },
    {
      name: "Reportado por",
      selector: (row) => row.reports.filter(r => {return r.reportStatus === "NEW"})[0].createdBy,
      sortable: true,
      width: '280px',
      wrap: true
    },
    {
        name: "Actions",
        selector: (row) => 
        <div className="btn-group">
          <Link to={`/question/${row.topicId[0].toString()}/${row.name}`}  >
          <button type="button" className="btn btn-primary btn-sm">
            <i className="fas fa-pencil-alt" />
          </button>
          </Link>
          
          <button type="button" className="btn btn-danger btn-sm">
            <i className="far fa-trash-alt" />
          </button>
        </div>,
        sortable: false,
      }
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getReports = async () => {
      try {
        const response = await axiosPrivate.get("/recreo-gw/Questions/QuestionsByReportStatus/NEW", {
          signal: controller.signal,
        });
        //console.log(response?.data);
        isMounted && SetReports(response?.data.map( item => {
          const { id: name, ...rest } = item;
          return { name, ...rest }
         }));
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    getReports();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  
  const tableProps = {
    columns: columns,
    data: reports
  };

  return (
    <section>
      <ContentHeader {...headerProps} />
      <section className="content">
        <div className="container-fluid">
          <CustomDataTable {...tableProps} />
        </div>
      </section>
    </section>
  )
}

export default QuestionReports
