import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CustomDataTable from '../../CustomDataTable';
import ContentHeader from '../ContentHeader';

const Questions = () => {
    let { topicId } = useParams();

    const [questions, SetQuestions] = useState();
    const axiosPrivate = useAxiosPrivate();
    const headerProps = {
      title: "Reactivos",
      parentName: "Temas",
      parentLink: "/topics",
      btn: {
        to: `/question/${topicId}/new`,
        icon: "fa-question",
        text: " +"
      },
    };
    const columns = [

      {
        name: "Id",
        selector: (row) => row.name,
        sortable: true,
        width: '200px',
      },
      {
        name: "Base",
        selector: (row) => row.questionBase,
        sortable: true,
        width: '450px',
        wrap: true,
      },
      
      {
        name: "Topic Id",
        selector: (row) =>row.topicId.toString(),
        sortable: true,
        width: '150px',
      },
      {
        name: "Categorias",
        selector: (row) =>row.tags.toString(),
        sortable: true,
        width: '350px',
        wrap: true,
      },
      {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
            <Link to={`/question/${row.topicId[0].toString()}/${row.name}`}  >
            <button type="button" className="btn btn-primary btn-sm">
              <i className="fas fa-pencil-alt" />
            </button>
            </Link>
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
        }
    ];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getQuestions = async () => {
          try {
            const response = await axiosPrivate.get( `/recreo-gw/Questions/GetQuestionByTopicId/${topicId} `, {
              signal: controller.signal,
            });
            //console.log(response?.data);
            isMounted && SetQuestions(response?.data.map( item => {
              const { id: name, ...rest } = item;
              return { name, ...rest }
             })); 
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
        };
    
        getQuestions();
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);
      
      const tableProps = {
        columns: columns,
        data: questions
      };


  return (
    <section>
        <ContentHeader {...headerProps} />
        <section className="content">
          <div className="container-fluid">
            <CustomDataTable {...tableProps} />
          </div>
        </section>
      </section>
  )
}

export default Questions
