import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer">
      <strong>
        © 2022 <a href="https://iusacomm.com">iusacomm</a>.
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>
  );
};

export default Footer;
