import { useEffect, useState } from "react";
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";

const Schools = () => {
  const headerProps = {
    title: "Escuelas",
    parentName: "Home",
    parentLink: "/",
    btn: {
      to: "/school",
      icon: "fa-school",
      text: " +"
    },
  };
  const [schools, SetSchools] = useState();
  const axiosPrivate = useAxiosPrivate();

  const columns = [
    {
      name: "Clave",
      selector: (row) => row.schoolCode,
      sortable: true,
    },
    {
        name: "Nombre",
        selector: (row) => row.name,
        sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Zona",
      selector: (row) => row.schoolZone,
      sortable: true,
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/recreo-gw/Schools", {
          signal: controller.signal,
        });
        //console.log(response?.data);
        isMounted && SetSchools(response?.data);
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const tableProps ={
      columns: columns,
      data :schools
  }

  return (
    <div>
      <ContentHeader {...headerProps} />
      <CustomDataTable {...tableProps}/>
    </div>
  );
};

export default Schools;
