import { useEffect, useState } from "react";
import Select from 'react-select'
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";
import { Link } from "react-router-dom";

const Topics = () => {

    const headerProps = {
        title: "Temas",
        parentName: "Home",
        parentLink: "/",
        btn: {
          to: "/topic/0",
          icon: "fa-cubes",
          text: " +"
        },
      };
    const [grades, SetGrades] = useState();
    const [subjects, SetSubjects] = useState();
    const [blocks, SetBlocks] = useState();
    const [topics, SetTopics] = useState();
    const axiosPrivate = useAxiosPrivate();

  const columns = [
    {
      name: "id",
      selector: (row) => row.topicId,
      sortable: true,
      width: '150px',
    },
    {
        name: "Name",
        selector: (row) =>  row.name,
        sortable: true,
        wrap: true
      },
    {
        name: "Aprendizaje",
        selector: (row) => row.expectedLearning.expectedLearningId,
        sortable: true,
        width: '150px',
    },
    {
        name: "Estatus",
        selector: (row) => row.isActive?"Activo":"Inactivo",
        sortable: true,
        width: '100px',
    },
    {
      name: "Categorias",
      selector: (row) =>row.tags.toString(),
      sortable: true,
      width: '250px',
      wrap: true,
    },
    {
      name: "Actions",
      selector: (row) => 
      <div className="btn-group">
        <Link to={`/questions/${row.topicId}`}  >
        <button type="button" className="btn btn-success btn-sm">
          <i className="fas fa-question" />
        </button>
        </Link>
        <Link to={`/resources/${row.topicId}`}   >
          <button type="button" className="btn btn-success btn-sm">
            <i className="fas fa-file-image" />
          </button>
        </Link>
        <Link to={`/topic/${row.topicId}`}  >
        <button type="button" className="btn btn-primary btn-sm">
          <i className="fas fa-pencil-alt" />
        </button>
        </Link>
        
        
        <button type="button" className="btn btn-danger btn-sm">
          <i className="far fa-trash-alt" />
        </button>
      </div>,
      sortable: false,
      width: '200px',
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCatalogs = async () => {
        try {
            const response = await axiosPrivate.get("/recreo-gw/Grades", {
              signal: controller.signal,
            });
            isMounted && SetGrades(response?.data.map( item => {
                const { gradeId: value, name: label, ...rest} = item;
                return { value,label, ...rest }
               }));

            const resposnse2 = await axiosPrivate.get("/recreo-gw/Blocks", {
                signal: controller.signal,
              });
            
              SetBlocks(resposnse2?.data.map( item => {
                const { blockId: value, name: label, ...rest} = item;
                return { value,label, ...rest }
               }));
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
    }

    getCatalogs();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleGradeChange  = async (option) =>{
    //console.log(option);
    try {
        const response = await axiosPrivate.get("/recreo-gw/Subjects/Grade/"+option.value);
        SetSubjects(response?.data.map( item => {
            const { subjectId: value, name: label, ...rest } = item;
            return { value,label , ...rest}
           }));
      } catch (err) {
        if (err?.message !== "canceled") {
          console.log(err);
        }
      }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    //console.log(object);
    try {
      const response = await axiosPrivate.get(
        `/recreo-gw/Topics/Subject/${object.subject}/Block/${object.block}`
      );

      //console.log(response);

      SetTopics(response.data)
      
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      }
    }
  };


  const tableProps ={
    columns: columns,
    data :topics
    }

const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '200px',
      'minWidth': '200px',
    }),
};

  return (
    <div>
        <ContentHeader {...headerProps} />
        <section className="content">
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="card-body row">
                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="grade"
                    placeholder="Grado" 
                    options = {grades}
                    styles = {customStyles}
                    onChange={(e) => handleGradeChange(e)}
                    />
                </div>

                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="block"  
                    placeholder="bloque"
                    options = {blocks}
                    styles = {customStyles}
                    />
                </div>

                <div className="input-group mb-3 col-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="subject"  
                    placeholder="materia"
                    options = {subjects}
                    styles = {customStyles}
                    />
                </div>

                <button type="submit" className="btn btn-primary float-right">
                  Buscar
                </button>


                </div>

            </form>
        
            <CustomDataTable {...tableProps}/>
        </div>
        
        </section>


    </div>
  )
}

export default Topics
