import { useState, useEffect } from "react";
import Select from 'react-select'
import Switch from "react-switch";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ContentHeader from "../ContentHeader";


const Competency = () => {

    let { id } = useParams();
    const headerProps = {
            title: `${id>0 ? "Editar" : "Agregar"} Competencia`,
            parentName: "Competencias",
            parentLink: "/competencies",
          };
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [competency, SetCompetency] = useState();
    const [subjects, SetSubjects] = useState();
    const [grades, SetGrades] = useState();
    const [tags, SetTags] = useState();
    const [competencyTags, SetCompetencyTags] = useState();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getCompetency = async () => {
          try {
            const response = await axiosPrivate.get(`/recreo-gw/competencies/${id}`, {
              signal: controller.signal,
            });
            //console.log(response?.data);
            isMounted && SetCompetency(response?.data);
            handleGradeChange({value:response?.data.subject.grade.gradeId})
            //handleSubjectChange({value:response?.data.subject.subjectId})

          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
          try {
            const response = await axiosPrivate.get(`/recreo-gw/competencyTags/competency/${id}`, {
              signal: controller.signal,
            });
            isMounted && SetCompetencyTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
        };

        const getTags = async () =>{
          try {
            const response = await axiosPrivate.get(`/recreo-gw/Tags/Type?tagType=COMPETENCY`, {
              signal: controller.signal,
            });
            //console.log(response?.data);
            isMounted && SetTags(response?.data)
          } catch (err) {
            if (err?.message !== "canceled") {
            console.log(err);
            }
          }
        };

        const getCatalogs = async () => {
            try {
                   const response = await axiosPrivate.get("/recreo-gw/Grades", {
                    signal: controller.signal,
                  });
                   SetGrades(response?.data.map( item => {
                      const { gradeId: value, name: label, ...rest} = item;
                      return { value,label, ...rest }
                     }));
             
  
            } catch (err) {
              if (err?.message !== "canceled") {
                 console.log(err);
              }
            }
          };

          getCatalogs();
          getTags();

        if(id>0){
          getCompetency();
        }
        
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);

      const handleSubmit = async (event) => {

        event.preventDefault();
       
        try {

            
          delete competency.subject;
            
            if(id === "0"){
             var response = await axiosPrivate.post(
                "/recreo-gw/competencies",
                JSON.stringify(competency)
              );
              let tagIds = competencyTags.map(x => x.tagId);
              await axiosPrivate.post(
                `/recreo-gw/competencyTags/Tags/${response?.data.competencyId}`,
                JSON.stringify(tagIds)
              );
            }else{
              await axiosPrivate.put(
                "/recreo-gw/competencies/"+competency.competencyId,
                JSON.stringify(competency)
              );
              let tagIds = competencyTags.map(x => x.tagId);
              await axiosPrivate.put(
                `/recreo-gw/competencyTags/Tags/${competency.competencyId}`,
                JSON.stringify(tagIds)
              );
            }

         
           navigate("/competencies");
          
          
        } catch (err) {
          if (!err?.response) {
            console.log("No Server Response");
          }
        }


      };
      const customStyles = {
        control: (base, state) => ({
          ...base,
          width: '300px',
          'minWidth': '300px',
        }),
    };

    

      const handleGradeChange  = async (option) =>{
        try {
            const response = await axiosPrivate.get("/recreo-gw/Subjects/Grade/"+option.value);
            SetSubjects(response?.data.map( item => {
                const { subjectId: value, name: label, ...rest } = item;
                return { value,label , ...rest}
               }));
               //console.log(competency,id)

            if(id==='0' && typeof competency === 'undefined'){
                SetCompetency({subject:{grade:{gradeId:option.value}}});
            }else if(competency) {
                competency.subject.grade.gradeId = option.value;
                SetCompetency(competency)
            }

              
            

          } catch (err) {
            if (err?.message !== "canceled") {
              console.log(err);
            }
          }
      }

      // const handleSubjectChange  = async (option) =>{
      //   try {
      //       if(competency?.subject){
      //         competency.subjectId = option.value;
      //           competency.subject.subjectId = option.value;
      //           SetCompetency(competency)
      //       }
            
      //     } catch (err) {
      //       if (err?.message !== "canceled") {
      //         console.log(err);
      //       }
      //     }
      // }


      
      const handleSwitchChange = nextChecked => {
        if(!competency){
            SetCompetency({
                isActive:nextChecked
            });
        }else{
            SetCompetency({ ...competency, isActive: nextChecked })
        }
      };

  return (
    <section>
    <ContentHeader {...headerProps} />
    <section className="content">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
              {id>0 ? "Editar" : "Agregar"} Competencia
              </h3>
            </div>
            <div className="card-body row">
              
            <div className="input-group mb-3 col-12">
            <label >Id: &nbsp; </label>
                {competency?.competencyId }
                  
            </div>
            <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="grade"
                    placeholder="Grado"
                    options = {grades}
                    styles = {customStyles}
                    value={ grades?.filter( grade => grade.value === 
                      competency?.subject?.grade.gradeId)}
                    onChange={(e) => handleGradeChange(e)}
                    />
            </div>
            
                <div className="input-group mb-3 col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-filter"></i>
                    </span>
                    </div>
                    <Select 
                    name="subject"  
                    placeholder="materia"
                    options = {subjects}
                    value={ subjects?.filter( subject => subject.value === 
                      competency?.subjectId)}
                    styles = {customStyles}
                    onChange={(e) => SetCompetency({...competency, subjectId: e.value})}
                    />
                </div>

                <div className="input-group mb-3 col-4">
                <label >Activo: &nbsp; </label>
                
                <Switch 
                    onChange={handleSwitchChange} 
                    checked={competency?.isActive ? true: false } 
                    // height={30}
                    // handleDiameter={30}
                    />
                </div>

            

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-cubes" />
                  </span>
                </div>
                <input
                  id="name"
                  type="textarea"
                  name="name"
                  className="form-control"
                  placeholder="Tema"
                  required = {true}
                  value={competency?.name}
                  onChange ={(e) => SetCompetency({ ...competency, name: e.target.value })}
                  
                /> 
              </div>

              <div className="input-group mb-3 col-12">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-tag" />
                  </span>
                </div>
                <input
                  type="textarea"
                  name="description"
                  className="form-control"
                  required = {true}
                  placeholder="Descripcion"
                  value={competency?.description}
                  onChange ={(e) => SetCompetency({ ...competency, description: e.target.value })}
                />
              </div>

              <div className="input-group mb-3 col-8">
                <label >Clasificación: &nbsp;</label>
                    <Select 
                    name="tags"  
                    placeholder="categorias"
                    isMulti
                    options = {tags}
                    getOptionLabel={(option) => `${option.tagClass}-${option.name}`}
                    getOptionValue={(option) => option.tagId}
                    value={ tags?.filter( tag => competencyTags?.some(qt => qt.tagId === tag.tagId))}
                    styles = {customStyles}
                    onChange ={(e) => SetCompetencyTags(e)}
                    />
                </div>

              
              
            

              
              
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </section>
  )
}

export default Competency
