import Login from "./components/views/auth/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/views/auth/RequireAuth";
import Home from "./components/views/Home";

import { Route, Routes } from "react-router-dom";
import Profile from "./components/views/Profile";
import Reset from "./components/views/auth/Reset";
import Unauthorized from "./components/Layout/Unauthorized";
import NotFound from "./components/Layout/NotFound";
import Users from "./components/views/admin/Users";
import Schools from "./components/views/admin/Schools";
import User from "./components/views/admin/User";
import School from "./components/views/admin/School";
import Tags from "./components/views/admin/Tags";
import Tag from "./components/views/admin/Tag";
import QuestionReports from "./components/views/admin/QuestionReports";
import NewQuestions from "./components/views/admin/NewQuestions";
import NewResources from "./components/views/admin/NewResources";
import Question from "./components/views/admin/Question";
import Topics from "./components/views/admin/Topics";
import Questions from "./components/views/admin/Questions";
import Topic from "./components/views/admin/Topic";
import Resource from "./components/views/admin/Resource";
import Resources from "./components/views/admin/Resources";
import ResourceReports from "./components/views/admin/ResourceReports";
import Competencies from "./components/views/admin/Competencies";
import Competency from "./components/views/admin/Competency";
import ExpectedLearnings from "./components/views/admin/ExpectedLearnings";
import ExpectedLearning from "./components/views/admin/ExpectedLearning";

const ROLES = {
  Root: "GlobalAdministrator",
  Admin: "Administrator",
  Supervisor: "Supervisor",
  Director: "SchoolDirector",
};

function App() {
  return (
    <Routes>
      
        <Route path="login" element={<Login />} />
        <Route path="reset" element={<Reset />} />

        <Route path="/" element={<Layout />}>

         
            <Route
              element={<RequireAuth allowedRoles={Object.values(ROLES)} />}
            >
              <Route path="/" element={<Home />} />
              <Route path="profile" element={<Profile />} />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={[ROLES.Root,ROLES.Admin]} />}
            >
              
              <Route path="users" element={<Users />} />
              <Route path="user" element={<User />} />
              <Route path="schools" element={<Schools />} />
              <Route path="school" element={<School />} />
              <Route path="tags" element={<Tags />} />
              <Route path="tag/:id" element ={<Tag />} />
              <Route path="competencies" element ={<Competencies />} />
              <Route path="competency/:id" element ={<Competency />} />
              <Route path="expectedLearnings/:competencyId" element ={<ExpectedLearnings />} />
              <Route path="expectedLearning/:id" element ={<ExpectedLearning />} />
              <Route path="topics" element ={<Topics />} />
              <Route path="topic/:id" element ={<Topic />} />
              <Route path="questions/:topicId" element ={<Questions />} />
              <Route path="question/:topicId/:id" element ={<Question />} />
              <Route path="qreports" element={<QuestionReports />} />
              
              <Route path="nquestions" element={<NewQuestions />} />
              <Route path="nresources" element={<NewResources />} />
              <Route path="resource_reports" element={<ResourceReports />} />
              <Route path="resources/:topic" element ={<Resources />} />
              <Route path="resource/:topic/:id" element ={<Resource />} />

              
            </Route>

            <Route path="unauthorized" element={<Unauthorized />} />
          
        </Route>
        
        <Route path="*" element={<NotFound />} />
      
    </Routes>
  );
}

export default App;
