import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { Outlet } from 'react-router-dom'
import Aside from "./Layout/Aside";

const Layout = () => {
  return (
    <main className="wrapper"> 
        <Header />
        <Aside />
        <div className="content-wrapper">
          <Outlet />
        </div> 
        <Footer />
        <div id="sidebar-overlay"></div>
    </main>
  )
}

export default Layout
