import axios from "axios";
const BASE_URL = 'https://iusacomm.com';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers:{ 
        'Content-Type': 'application/json',
        "Accept": "/",
        "Cache-Control": "no-cache",
        //"Cookie": 'cross-site-cookie=whatever; SameSite=None; Secure'
        //'Set-Cookie': ''
    }
});
