import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../ContentHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CustomDataTable from "../../CustomDataTable";
import { Link } from "react-router-dom";

const ExpectedLearnings = () => {

    let { competencyId } = useParams();
    const headerProps = {
        title: "Aprendizaje esperado",
        parentName: "Home",
        parentLink: "/",
        btn: {
          to: "/expectedLearning/0",
          icon: "fa-cubes",
          text: " +"
        },
      };

    const [expectedLearnings, SetExpectedLearnings] = useState();
    const axiosPrivate = useAxiosPrivate();

    const columns = [
        {
          name: "id",
          selector: (row) => row.expectedLearningId,
          sortable: true,
          width: '150px',
        },
        {
            name: "Name",
            selector: (row) =>  row.name,
            sortable: true,
            wrap: true
          },
        {
            name: "Estatus",
            selector: (row) => row.isActive?"Activo":"Inactivo",
            sortable: true,
            width: '100px',
        },
        {
          name: "Actions",
          selector: (row) => 
          <div className="btn-group">
            {/* <Link to={`/questions/${row.competencyId}`}  >
            <button type="button" className="btn btn-success btn-sm">
              <i className="fas fa-question" />
            </button>
            </Link> */}
            
            <Link to={`/expectedLearning/${row.expectedLearningId}`}  >
            <button type="button" className="btn btn-primary btn-sm">
              <i className="fas fa-pencil-alt" />
            </button>
            </Link>
            
            
            <button type="button" className="btn btn-danger btn-sm">
              <i className="far fa-trash-alt" />
            </button>
          </div>,
          sortable: false,
          width: '200px',
        },
      ];

      useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getCatalogs = async () => {
            try {
                const response = await axiosPrivate.get(
                  `/recreo-gw/ExpectedLearnings/Competency/${competencyId}`
                );
          
                //console.log(response);
          
                isMounted && SetExpectedLearnings(response.data)
                
              } catch (err) {
                if (!err?.response) {
                  console.log("No Server Response");
                }
              }
        }
    
        getCatalogs();
    
        return () => {
          isMounted = false;
          controller.abort();
        };
      }, []);

      const tableProps ={
        columns: columns,
        data :expectedLearnings
        }


  return (
    <div>
        <ContentHeader {...headerProps} />
        <section className="content">
        <div className="container-fluid">
            <CustomDataTable {...tableProps}/>
        </div>
        
        </section>


    </div>
  )
}

export default ExpectedLearnings
